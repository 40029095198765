import { Steps } from "antd";
import React from "react";

interface Props {
  currentStep: number;
}

export default function CustomSteps({ currentStep }: Props) {
  return (
    <Steps
      current={currentStep}
      items={[
        {
          title: "Etape 1",
          description: "Activez votre email",
        },
        {
          title: "Etape 2",
          description: "Sélectionnez votre type de compte",
        },
        {
          title: "Etape 3",
          description: "Renseignez vos informations",
        },
      ]}
    />
  );
}
