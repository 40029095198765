type ColorsThiana = {
  thiana: Palette;
  hashtags: HashtagsColor;
};
type Shades = {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
};
type HashtagsColor = {
  radiology: string;
  neurology: string;
  cardiology: string;
  cancerology: string;
  gynecology: string;
  pulmonology: string;
  urology: string;
  gastroEnterology: string;
  ultrasound: string;
  nephrology: string;
  infectiology: string;
  emergency: string;
};
type Palette = {
  primary: Shades;
  secondary: Shades;
  third: Shades;
  success: Shades;
  error: Shades;
  warning: Shades;
  grey: Shades;
  bluegrey: Shades;
  blue: Shades;
};

export const getHashtagColor = (hashtag: string) => {
  let tmpSliced = hashtag.slice(0, 3);
  switch (tmpSliced) {
    case "rad":
      return colors.hashtags.radiology;
    case "neu":
      return colors.hashtags.neurology;
    case "car":
      return colors.hashtags.cardiology;
    case "can":
      return colors.hashtags.cancerology;
    case "gyn":
      return colors.hashtags.gynecology;
    case "pne":
      return colors.hashtags.pulmonology;
    case "uro":
      return colors.hashtags.urology;
    case "gas":
      return colors.hashtags.gastroEnterology;
    case "éch":
      return colors.hashtags.ultrasound;
    case "nép":
      return colors.hashtags.nephrology;
    case "inf":
      return colors.hashtags.infectiology;
    case "urg":
      return colors.hashtags.emergency;
    default:
      return colors.thiana.primary[400];
  }
};

const colors: ColorsThiana = {
  thiana: {
    primary: {
      50: "#BFD9E6",
      100: "#A8D1E6",
      200: "#91C9E6",
      300: "#7AC1E6",
      400: "#63B9E6",
      500: "#4DB2E6",
      600: "#3C8AB3",
      700: "#2B6380",
      800: "#1A3B4D",
      900: "#112733",
    },
    secondary: {
      50: "#BEB9C4",
      100: "#847A91",
      200: "#7B6B91",
      300: "#735D91",
      400: "#6B4E91",
      500: "#634091",
      600: "#523578",
      700: "#402A5E",
      800: "#2F1F45",
      900: "#1E132B",
    },
    third: {
      50: "#E1FFDE",
      100: "#9CFF92",
      200: "#8CE683",
      300: "#7DCC74",
      400: "#6DB366",
      500: "#5CB253",
      600: "#4F9947",
      700: "#42803B",
      800: "#35662F",
      900: "#284D23",
    },
    success: {
      50: "#E6FFF7",
      100: "#99FFDD",
      200: "#4DFFC4",
      300: "#14EDA6",
      400: "#12D494",
      500: "#10B981",
      600: "#0EA170",
      700: "#0C875E",
      800: "#0A6E4C",
      900: "#08543A",
    },
    error: {
      50: "#FFD5CC",
      100: "#FFAA99",
      200: "#FF9580",
      300: "#FF7F66",
      400: "#FF6A4D",
      500: "#EF4444",
      600: "#D63C3C",
      700: "#BD3535",
      800: "#A32E2E",
      900: "#8A2727",
    },
    warning: {
      50: "#FFFCCC",
      100: "#FFFAB3",
      200: "#FFF899",
      300: "#FFF780",
      400: "#FCEF35",
      500: "#FCD34D",
      600: "#E3AD5D",
      700: "#A67855",
      800: "#73533B",
      900: "#402421",
    },
    grey: {
      50: "#FDFFFF",
      100: "#F8F9FA",
      200: "#E9ECEF",
      300: "#DEE2E6",
      400: "#CED4DA",
      500: "#ADB5BD",
      600: "#6C757D",
      700: "#495057",
      800: "#343A40",
      900: "#212529",
    },
    bluegrey: {
      50: "#D9E2EC",
      100: "#BCCCDC",
      200: "#9FB3C8",
      300: "#9FB3C8",
      400: "#829AB1",
      500: "#627D98",
      600: "#486581",
      700: "#334E68",
      800: "#243B53",
      900: "#102A43",
    },
    blue: {
      50: "#EDF6FF",
      100: "#CCE3FA",
      200: "#99C7F5",
      300: "#66AAF0",
      400: "#338EEB",
      500: "#0072E6",
      600: "#005BB8",
      700: "#00448A",
      800: "#002E5C",
      900: "#00172E",
    },
  },
  hashtags: {
    radiology: "#5B507A",
    neurology: "#5B618A",
    cardiology: "#9EADC8",
    cancerology: "#6C4B5E",
    gynecology: "#92C9B1",
    pulmonology: "#1C3738",
    urology: "#8BAAAD",
    gastroEnterology: "#B48291",
    ultrasound: "#A499B3",
    nephrology: "#D0BCD5",
    infectiology: "#70A288",
    emergency: "#FFBB77",
  },
};

export default colors;
