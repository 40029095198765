import { notification } from "antd";

export default function handleError(response: Response) {
  const [api, contextHolder] = notification.useNotification();
  console.error(response.type, response.status, response.statusText);
  api.error({
    message: "Une erreur s'est produite.",
    description: "Merci de réessayer ultérieurement",
    placement: "topRight",
  });
}
