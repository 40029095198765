/* THIS DEFINE USERS TYPES */
export type Users = User[];
export type User = {
  id: string;
  created_at: string;
  updated_at: string;
  first_name: string;
  last_name: string;
  avatar: string;
  email: string;
  status: String;
  speciality: String;
  year_cycle: String;
  zip_code: String;
  user_id: string;
  api_key: string;
  cgu_accepted_at: string;
};
export type UsersResponse = {
  data: Users;
};
export type UserResponse = {
  data: User[];
};

export const emptyUser: User = {
  id: "-1",
  created_at: "",
  updated_at: "",
  first_name: "",
  last_name: "",
  avatar: "",
  email: "",
  status: "",
  speciality: "",
  year_cycle: "",
  zip_code: "",
  user_id: "",
  api_key: "",
  cgu_accepted_at: "",
};
