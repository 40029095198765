import useScreenSize from "../../hooks/useScreenSize";
import breakpoints from "../../theme/antdesign";
import colors from "../../theme/thiana";
import Visual from "../../assets/logo/doctor-patient-conversation.svg";
import ThianaLogo from "../../assets/logo/THIANA_LOGO_LIGHT.svg";
import logo_head from "../../assets/logo/THIANA_LOGO_HEAD.svg";
import { Flex, Typography } from "antd";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPills } from "@fortawesome/free-solid-svg-icons";

interface Props {
  children: JSX.Element;
}
export default function Layout(props: Props) {
  let { currentScreenSize } = useScreenSize();
  return (
    <Flex
      flex={1}
      style={{
        alignItems: "start",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      {currentScreenSize <= breakpoints.md ? null : (
        <Flex
          flex={1}
          vertical
          style={{
            backgroundColor: colors.thiana.grey[100],
            height: "100%",
          }}
        >
          <Flex flex={1} align="center" vertical>
            <Flex
              flex={1}
              justify="start"
              vertical
              style={{
                backgroundImage: `url(${Visual})`,
                backgroundSize: "80%",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                paddingTop: 80,
                width: "100%",
                maxWidth: "896px",
                minHeight: "1000px",
              }}
            >
              <Flex
                vertical
                justify="center"
                align="center"
                style={{
                  width: "100%",
                }}
              >
                <img src={ThianaLogo} alt="Logo" style={{ width: 300 }}></img>
                <div style={{ color: "black", fontSize: 14 }}>
                  L'intelligence artificielle au service des médecins
                </div>
              </Flex>
              <Flex flex={1} align="end" vertical style={{ width: "100%" }}>
                {/* <Flex style={{ width: 400, padding: 16 }}>
                <Flex>
                  <img src={logo_head} style={{ height: 48 }} />{" "}
                  <Flex vertical gap={4}>
                    <Flex
                      style={{
                        border: "1px solid white",
                        padding: 8,
                        borderRadius: 8,
                      }}
                    >
                      <Typography.Text>
                        Après analyse de votre conversation, voilà les documents
                        que j'ai rédigé pour vous
                      </Typography.Text>
                    </Flex>
                    <Flex
                      style={{
                        border: "1px solid white",
                        padding: 8,
                        borderRadius: 8,
                      }}
                    >
                      <Flex>
                        <FontAwesomeIcon icon={faPills} />
                      </Flex>
                      <Typography.Text>
                        Après analyse de votre conversation, voilà les documents
                        que j'ai rédigé pour vous
                      </Typography.Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex> */}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
      <Flex
        flex={1}
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        {props.children}
      </Flex>
    </Flex>
  );
}
