import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { apiAuth } from "../api/api-thiana-client/Configuration";
import { AuthApi } from "@thiana/api-thiana-client";
import { apiConfiguration } from "../api/api-thiana-client/Configuration";

export default function useRegister() {
  let authApi = new AuthApi(apiConfiguration);
  let navigate = useNavigate();

  const register = async (
    email: string,
    password: string,
    cgu_accepted: boolean
  ) => {
    // We try to register user
    let response = await authApi.authUserCreateRaw({
      register: {
        email,
        password,
        cgu_accepted,
      },
    });
    if (response.raw.status === 201) {
      navigate("/activate");
      notification.success({
        message: "Inscription réussie.",
        description:
          "Confirmez votre compte avec le code de validation reçu par email.",
      });
    }
  };

  return { register };
}
