import { useState, useEffect } from "react";
import breakpoints from "../theme/antdesign";

export default function useScreenSize() {
  const [currentScreenSize, setCurrentScreenSize] = useState<number>(0);

  useEffect(() => {
    const { innerWidth: width } = window;
    setCurrentScreenSize(width);

    function handleResize() {
      const { innerWidth: width } = window;
      setCurrentScreenSize(width);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getUpperBreakpoints = () => {
    if (currentScreenSize < breakpoints.xs) return "xs";
    else if (
      currentScreenSize >= breakpoints.xs &&
      currentScreenSize < breakpoints.sm
    )
      return "sm";
    else if (
      currentScreenSize >= breakpoints.sm &&
      currentScreenSize < breakpoints.md
    )
      return "md";
    else if (
      currentScreenSize >= breakpoints.md &&
      currentScreenSize < breakpoints.lg
    )
      return "lg";
    else if (currentScreenSize > breakpoints.lg) return "xl";
  };

  return { currentScreenSize, getUpperBreakpoints };
}
