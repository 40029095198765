import { createContext } from "react";
import { User, emptyUser } from "../types/users";

export default createContext({
  /* User  */
  user: emptyUser,
  updateUser: (arg: User) => {},

  accountType: "",
  updateAccountType: (arg: string) => {},
});
