import { RequestContext } from "@thiana/api-thiana-client";

// export const preMiddleware = async (context: RequestContext) => {
//   const accessJWT = localStorage.getItem("accessJWT");
//   const refreshJWT = localStorage.getItem("accessJWT");
//   if (accessJWT && refreshJWT) {
//     context.init.headers = {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${localStorage.getItem("accessJWT")}`,
//       "X-Refresh-Token": `${localStorage.getItem("refreshJWT")}`,
//     };
//     return context;
//   } else {
//     context.init.headers = {
//       "Content-Type": "application/json",
//     };
//     return context;
//   }
// };

export const preMiddleware = async (context: RequestContext) => {
  context.init.headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessJWT")}`,
    "X-Refresh-Token": `${localStorage.getItem("refreshJWT")}`,
  };
  return context;
};
