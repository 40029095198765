import * as React from "react";
import { useEffect, useState } from "react";
import Login from "./login/Login";
import Register from "./register/Register";
import ForgotPassword from "./forgotpassword/ForgotPassword";
import { useNavigate, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { AccessToken } from "../../types/token";
import { Alert, Flex, notification } from "antd";
import { hasToken } from "../../utils/security";
type CurrentForm = "Login" | "Register" | "ForgotPassword";

export default function AuthController(props: any) {
  let navigate = useNavigate();
  const fetching = React.useRef<boolean>(true);
  const checkParamURL = React.useRef<boolean>(true);
  const [currentForm, setCurrentForm] = useState<CurrentForm>("Login");
  const [isUnderMaintenance, setIsUnderMaintenance] = useState<boolean>(false);
  let params = useParams();

  useEffect(() => {
    if (checkParamURL.current) {
      checkParamURL.current = false;
      if (params.auth === "login") {
        setCurrentForm("Login");
      } else if (params.auth === "register") {
        setCurrentForm("Register");
      } else if (params.auth === "forgotpassword") {
        setCurrentForm("ForgotPassword");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On regarde si le back app est ON ou OFF
  useEffect(() => {
    (async () => {
      try {
        let response = await fetch(
          process.env.REACT_APP_URL_BACKAPP + "/health"
        );
        if (response.status !== 200) {
          setIsUnderMaintenance(true);
          notification.warning({
            message: "Oups..",
            description:
              "Le site est actuellement en maintenance. Désolé pour la gêne occasionnée.",
          });
        } else setIsUnderMaintenance(false);
      } catch (error) {
        setIsUnderMaintenance(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (fetching.current) {
      fetching.current = false;
      let decodedAccessToken: AccessToken;
      // Check if there is a token in localStorage
      let encodedAccessToken = localStorage.getItem("accessJWT");
      let encodedRefreshToken = localStorage.getItem("refreshJWT");
      if (encodedAccessToken !== null && encodedRefreshToken !== null) {
        decodedAccessToken = jwt_decode(encodedAccessToken);
        // Si le compte est activé
        if (decodedAccessToken.act) {
          // Si le professionel est déjà créé (le professionel est crée lors du postProfessional lors du submitWizard)
          console.log("decodedAccessToken.pro", decodedAccessToken.pro);
          if (decodedAccessToken.pro) {
            navigate("/choose-your-app");
            if (hasToken(encodedAccessToken))
              notification.success({
                message: "Connexion",
                description: "Vous êtes connecté(e).",
              });
          }
          // Si le professionel n'est pas encore crée
          else navigate("/informations");
        } else {
          navigate("/activate");
          notification.error({
            message: "Ce compte n'est pas activé.",
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderForm = () => {
    switch (currentForm) {
      case "Login":
        return <Login setCurrentForm={setCurrentForm} />;
      case "Register":
        return <Register setCurrentForm={setCurrentForm} />;
      case "ForgotPassword":
        return <ForgotPassword setCurrentForm={setCurrentForm} />;
      default:
        break;
    }
  };

  return (
    <Flex vertical flex={1}>
      {renderForm()}

      {isUnderMaintenance ? (
        <Flex
          style={{
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            width: "50%",
            top: 200,
          }}
        >
          <Alert
            style={{ width: "80%", textAlign: "center" }}
            type="error"
            message={
              "Le site est actuellement en maintenance. Nous vous prions de bien vouloir nous excuser pour la gêne occasionnée."
            }
          />
        </Flex>
      ) : null}
    </Flex>
  );
}
