import { useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Title from "antd/es/typography/Title";
import notification from "antd/es/notification";
import { Button, Flex } from "antd";
import colors from "../../../theme/thiana";
import { apiUsers } from "../../../api/api-thiana-client/Configuration";
import CustomSteps from "../../protected/components/CustomSteps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { errorWithCapitalization } from "../../../utils/errors";
import { BackendError } from "../../../types/BackendErrors";

export default function Activate() {
  let navigate = useNavigate();
  let { a } = useParams();
  let activateRef = useRef<boolean>(false);

  /**
   * Ce middleware permet de récupérer (et stocker dans le localStorage) les nouveaux tokens si la requête revient en success
   * Si la reqûete revient en erreur 401, on déconnecte le user via la route /logout
   * @param response réponse de la requête
   * @returns
   */
  const middleware = async (response: Response) => {
    // Success
    if (response.status >= 200 && response.status < 300) {
      let accessToken = response.headers.get("X-Access-Token");
      let refreshToken = response.headers.get("X-Refresh-Token");
      if (accessToken) localStorage.setItem("accessJWT", accessToken);
      if (refreshToken) localStorage.setItem("refreshJWT", refreshToken);
    }
    // Erreur
    else {
      // Si erreur 401 Unauthorized => logout
      if (response.status === 401) window.location.replace("/logout");
      // Sinon on affiche juste l'erreur renvoyé par le back
      let loginResponse = await response.json();
      loginResponse.errors?.forEach((error: BackendError) => {
        notification.error({
          message: "Erreur",
          description: errorWithCapitalization(error.message),
        });
      });
    }
    return response;
  };

  /* Activate */
  const postActivate = async (accessToken: string) => {
    return await middleware(
      await fetch(process.env.REACT_APP_URL_BACKAPP + "/auth/users/activate", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      })
    );
  };

  useEffect(() => {
    // On tente d'activer le compte avec le token passé dans l'url
    if (!activateRef.current) {
      activateRef.current = true;
      if (a) {
        (async () => {
          let res = await postActivate(a);
          if (res.status === 200) {
            notification.success({
              message: "Activation du compte réussie.",
              description: "Votre compte est désormais actif.",
            });
            navigate("/select-account-type");
          } else {
            navigate("/logout");
            notification.error({
              message: "Erreur",
              description: "Erreur lors de l'activation de votre compte.",
            });
          }
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendEmail = async () => {
    let response = await apiUsers.sendActivationEmailRaw();
    if (response.raw.status === 200) {
      notification.success({
        message: "Email envoyé",
        description:
          "Un email pour activer votre compte vient de vous être envoyé.",
      });
    } else {
      navigate("/logout");
      notification.error({
        message: "Erreur",
        description: "Erreur lors de l'envoi du lien d'activation par email.",
      });
    }
  };
  return (
    <Flex vertical style={{ padding: "32px" }} gap={32}>
      <Flex vertical gap={16}>
        <Flex>
          <Button
            disabled
            size="large"
            onClick={() => navigate("/select-account-type")}
            icon={<FontAwesomeIcon icon={faChevronLeft} />}
          >
            Retour
          </Button>
        </Flex>

        <Flex style={{ width: "100%" }}>
          <CustomSteps currentStep={0} />
        </Flex>
      </Flex>
      <Flex vertical gap={16} flex={12} justify="start">
        <div style={{ fontSize: 30, fontWeight: "bold" }}></div>
        <Title level={1}>Activez votre compte</Title>
        <div style={{ fontSize: 14, textAlign: "justify" }}>
          Lors de votre inscription, un email avec un lien de validation a été
          envoyé à votre adresse email. Cliquez sur le lien de validation pour
          valider votre compte. Si vous ne trouvez pas l'email, pensez à
          vérifier dans vos spams.
        </div>

        <div style={{ fontSize: 14, textAlign: "justify" }}>
          Si vous n'avez pas reçu l'email ou que vous ne le retrouvez pas,{" "}
          <span
            onClick={sendEmail}
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            cliquez ici pour recevoir un nouvel email.
          </span>
        </div>
      </Flex>
      <Flex flex={1} align="center" justify="center">
        <Button
          type="text"
          danger
          onClick={() => navigate("/logout")}
          style={{
            color: colors.thiana.grey[500],
          }}
        >
          Déconnexion
        </Button>
      </Flex>
    </Flex>
  );
}
