import styled from "styled-components";
import { Button, Input } from "antd";
import colors from "../theme/thiana";

export const MyPrimaryButton = styled(Button)`
  box-shadow: none;
  border: none;
  margin: 10px;
  text-align: center;
  width: 100%;
  background-color: ${(props) =>
    props.disabled ? "grey" : colors.thiana.primary[400]};
  color: white;
  &:hover {
    background-color: ${(props) =>
      props.disabled ? "whitesmoke" : colors.thiana.primary[600]};
    color: ${(props) => (props.disabled ? null : "white !important")};
  }
`;

export const MySecondaryButton = styled(Button)`
  box-shadow: none;
  border: none;
  background-color: ${colors.thiana.secondary[400]};
  color: white;
  &:hover {
    background-color: ${colors.thiana.secondary[600]};
    color: white !important;
  }
`;
export const MyUnstyledButton = styled(Button)`
  border: none;
  box-shadow: none;
  width: 100%;
`;
export const MyInput = styled(Input)`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
`;

export const MyH1 = styled.h1`
  margin-top: 10px;
  margin-bottom: 10px;
`;
