import React from "react";

export default function DownloadThianaAssistant() {
  return (
    <div style={{ background: "#F5F5F5" }}>
      <div
        style={{
          minHeight: "100vh",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 16,
        }}
      >
        <h1>Choisissez l'application qui correspond à votre système</h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16,
          }}
        >
          <div
            style={{
              height: 200,
              width: 500,
              border: "1px solid #C5C5C5",
              borderRadius: 32,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <h2>Mac OS</h2>
              <p>Compatible avec les versions avec processeur Intel comme M1</p>
              <p>Nécessite une version de Mac OS au moins à 10.13</p>
              <a href="https://thiana-widget.s3.eu-west-3.amazonaws.com/Thiana+Widget_1.1.0_universal.dmg">
                Télécharger ici
              </a>
            </div>
          </div>
          <div
            style={{
              height: 200,
              width: 500,
              border: "1px solid #C5C5C5",
              borderRadius: 32,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <h2>Windows</h2>
              <p>Compatible avec windows 8 et les versions plus anciennes</p>
              <a href="https://thiana-widget.s3.eu-west-3.amazonaws.com/Thiana+Widget.exe">
                Télécharger ici
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
