import { notification } from "antd";
import { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../../../context/Context";
import { emptyUser } from "../../../types/users";

export default function Logout(props: any) {
  const done = useRef<boolean>(false);
  let navigate = useNavigate();
  const { updateUser } = useContext(AppContext);
  useEffect(() => {
    if (!done.current) {
      done.current = true;
      localStorage.removeItem("accessJWT");
      localStorage.removeItem("refreshJWT");
      updateUser(emptyUser);
      navigate("/");
      notification.error({
        message: "Déconnexion",
        description: "Vous êtes déconnecté(e).",
      });
    }
  });
  return <></>;
}
