import { Button, Flex } from "antd";
import React, { useContext, useState } from "react";
import Title from "antd/es/typography/Title";
import CustomSteps from "../components/CustomSteps";
import { useTheme } from "styled-components";
import colors from "../../../theme/thiana";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import AppContext from "../../../context/Context";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";

interface ListItemProps {
  title: string;
  description: string;
  onClick?: () => void;
}
const ListItem = ({ title, description, onClick }: ListItemProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <motion.div
      onClick={onClick}
      style={{
        cursor: "pointer",
        display: "flex",
        gap: 8,
        flexDirection: "column",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      whileHover={{ scale: 1.01 }}
      whileTap={{ scale: 0.99 }}
      transition={{ duration: 0.3, type: "spring" }}
    >
      <Flex
        style={{
          borderRadius: 16,
          border: "1px solid " + colors.thiana.grey[300],
          padding: 32,
        }}
        gap={16}
        align="center"
        justify="space-between"
      >
        <Flex gap={8} vertical>
          <span style={{ fontSize: 24, fontWeight: 500 }}>{title}</span>
          <span style={{ fontSize: 16, fontWeight: 300 }}>{description}</span>
        </Flex>
        <FontAwesomeIcon size="xl" icon={faChevronRight} />
      </Flex>
    </motion.div>
  );
};

export default function SelectAccountType() {
  const { updateAccountType } = useContext(AppContext);
  const navigate = useNavigate();
  return (
    <Flex vertical style={{ padding: "32px" }} gap={32}>
      <Flex vertical gap={16}>
        <Flex>
          <Button
            disabled
            size="large"
            onClick={() => navigate("/select-account-type")}
            icon={<FontAwesomeIcon icon={faChevronLeft} />}
          >
            Retour
          </Button>
        </Flex>

        <Flex style={{ width: "100%" }}>
          <CustomSteps currentStep={1} />
        </Flex>
      </Flex>
      <Flex flex={12} vertical justify="start">
        <Flex align="start" style={{ width: "100%" }}>
          <Title level={1}>Choisissez votre type de compte</Title>
        </Flex>
        <Flex vertical gap={8}>
          <ListItem
            title="Médecin"
            description="Ce type de compte donne accès à l’ensemble des fonctionnalités de
              Thiana"
            onClick={() => {
              updateAccountType("doctor");
              navigate("/informations");
            }}
          />

          <ListItem
            title="Etudiant en médecine"
            description="Sur présentation d’un justificatif, vous disposez d’avantages et de réductions."
            onClick={() => {
              updateAccountType("med-student");
              navigate("/informations");
            }}
          />

          <ListItem
            title="Secrétaire médicale"
            description="Permet d’accéder à une interface adaptée, ainsi qu’à des fonctionnalités et des tarifs spécifiques au sein des espaces de travail"
            onClick={() => {
              updateAccountType("secretary");
              navigate("/informations");
            }}
          />

          <ListItem
            title="Autre"
            description="Pour tout autre type d'utilisateur."
            onClick={() => {
              updateAccountType("other");
              navigate("/informations");
            }}
          />
        </Flex>
      </Flex>
      <Flex flex={1} align="center" justify="center">
        <Button
          type="text"
          danger
          onClick={() => navigate("/logout")}
          style={{
            color: colors.thiana.grey[500],
          }}
        >
          Déconnexion
        </Button>
      </Flex>
    </Flex>
  );
}
