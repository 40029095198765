import { useNavigate } from "react-router-dom";
import { hasToken, isActivate, isProfessionalCreated } from "../utils/security";
import { useContext, useEffect } from "react";
import Context from "../context/Context";

const Protected = (props: any) => {
  let navigate = useNavigate();
  useEffect(() => {
    if (hasToken()) {
      if (isActivate()) {
        if (isProfessionalCreated()) {
          navigate("/choose-your-app");
        }
      } else navigate("/activate");
    } else navigate("/");
  }, [navigate]);

  return props.children;
};
export default Protected;
